<template>
  <div>
    <div v-if="value">
      <v-select
        dense
        v-model="select"
        :hint="`${value.label}`"
        :items="items"
        item-text="value"
        item-value="value"
        :label="value.label"
        persistent-hint
        return-object
        single-line
        clearable
      ></v-select>
      <div v-if="hasFields">
        <fields
          v-if="select"
          :fields="select.fields"
          :value="value.label"
          @updateResult="updateResult"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      default: null,
    },
  },
  data: () => ({
    select: null,
    updated: false,
    res: {},
  }),
  created() {
    this.res[`${this.value.id}`] = {};
    if (this.value.myValue) {
      console.log("this.value.myValue", this.value.myValue);
      // console.log("this.items", this.items);
      let objectWithCapacitor = this.items.find(
        (obj) => obj.value === this.value.myValue
      );
      this.select = objectWithCapacitor;
      console.log("this.select", this.select);
    }
  },
  computed: {
    items() {
      if (this.updated || !this.updated) {
        if (this.value) {
          if (this.value.options) {
            let arr = null;
            arr = Object.keys(this.value.options).map((key) => ({
              ...this.value.options[key],
              id: key,
              field_id: this.field_id,
            }));
            return arr;
          }
        }
      }
      return [];
    },
    hasFields() {
      if (this.select) {
        if (this.select.fields) {
          return true;
        }
      }
      return false;
    },
  },
  components: {
    fields: () => import("@/components/PCB/componentsCreator/fields"),
  },
  watch: {
    select() {
      if (this.select != null) {
        // this.res[`${this.value.id}`][`${this.value.label}`] = this.select.value;
        // this.res[`${this.value.id}`]["id"] = this.select.id;

        this.res[`${this.value.id}`][`label`] = this.value.label;
        this.res[`${this.value.id}`][`value`] = this.select.value;
        this.res[`${this.value.id}`]["id"] = this.select.id;

        if (this.select.fields) {
          this.res[`${this.value.id}`].fields = {};
        } else {
          delete this.res[`${this.value.id}`].fields;
        }
        this.$emit("updateResult", this.res);
        // this.$emit("newValue", {
        //   label: this.value.label,
        //   value: this.select.value,
        //   id: this.value.id,
        // });
        // console.log("selected new value", this.res);
        this.updated = !this.updated;
      }
    },
  },
  methods: {
    updateResult(payload) {
      // console.log("from mySelect before", payload);
      // this.res[`${this.value.id}`] = payload;
      // this.res[`${this.value.id}`]["id"] = this.select.id;
      // this.res[`${this.value.id}`][`${this.value.label}`] = this.select.value;

      this.res[`${this.value.id}`] = payload;
      this.res[`${this.value.id}`]["id"] = this.select.id;
      this.res[`${this.value.id}`][`label`] = this.value.label;
      this.res[`${this.value.id}`][`value`] = this.select.value;

      this.$emit("updateResult", this.res);
    },
    newValue(payload) {
      this.$emit("newValue", payload);
    },
  },
};
</script>

<style>
</style>